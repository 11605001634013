<template>
  <div :class="rootClass">
    <ul :class="`${rootClass}__wrapper`">
      <li
        v-for="(dataImage, index) in dataImagesLocal"
        :key="index"
        :class="`${rootClass}__item`"
      >
        <div
          v-if="!isMultiple"
          :class="
            classThumbnail(singleSelected.id, dataImage.id, dataImage.disabled)
          "
          @click="onSelectImage(dataImage)"
        >
          <b-overlay
          
            variant="dark"
            show
            
            :opacity="dataImage.id != 1 && dataImage.id != 2 ? 0.18 : 0.0"
            blur="2px"
            rounded="sm"
            no-center
          >
          <template #overlay>
          
            <b-list-group v-if="dataImage.id != 1 && dataImage.id != 2 " class="m-0 p-0 mt-1 mr-3" >
              <b-list-group-item
                class="d-flex justify-content-between align-items-center text-xs m-0 p-0"
              >
              <span class="font-bold">Total Mailed:</span>
                <b-badge variant="primary" pill>{{ dataImage.mailed }}</b-badge>
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center text-xs m-0 p-0"
              >
              <span class="font-bold">Calls:</span>
                <b-badge variant="primary" pill>{{ dataImage.called }}</b-badge>
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center text-xs m-0 p-0"
              >
              <span class="font-bold">Scans:</span>
                <b-badge variant="primary" pill>{{ dataImage.scanned }}</b-badge>
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center text-xs m-0 p-0"
              >
              <span class="font-bold">Scan Rate:</span>
                <b-badge variant="primary" pill>{{ dataImage.scan_ratio }}</b-badge>
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center text-xs m-0 p-0"
              >
              <span class="font-bold">Call Rate:</span>
                <b-badge variant="primary" pill>{{ dataImage.call_ratio }}</b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group v-else class="m-0 p-0" > <div class="hidden">.</div></b-list-group>
            </template>
            <img
              :id="dataImage.id"
              :src="dataImage.src"
              :alt="dataImage.alt"
              :height="h"
              :width="w"
              :class="`${rootClass}__img`"
            />
          </b-overlay>
          <label
            v-if="useLabel"
            :for="dataImage.id"
            :class="`${rootClass}__lbl bg-white w-full font-bold pl-1`"
            >{{ dataImage.alt }}</label
          >
        </div>

        <div
          v-if="isMultiple"
          :class="classThumbnailMultiple(dataImage.id, dataImage.disabled)"
          @click="onSelectMultipleImage(dataImage)"
        >
          <b-overlay
         
            variant="light"
            show
            opacity="0.58"
            blur="2px"
            rounded="sm"
            no-center
          >
          <template #overlay >
            <b-list-group v-if="dataImage.id != 1 || dataImage.id != 2 " class="m-0 p-0" >
            
         
              <b-list-group-item
                class="d-flex justify-content-between align-items-center text-xs m-0 p-0"
              >
              <span class="font-bold">Total Maileda:</span>
                <b-badge variant="primary" pill>{{ dataImage.mailed }}</b-badge>
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center text-xs m-0 p-0"
              >
              <span class="font-bold">Calls:</span>
                <b-badge variant="primary" pill>{{ dataImage.called }}</b-badge>
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center text-xs m-0 p-0"
              >
              <span class="font-bold">Scans:</span>
                <b-badge variant="primary" pill>{{ dataImage.scanned }}</b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group v-else class="m-0 p-0" > <div class="hidden">.</div></b-list-group>
            </template>
        
            <img
              :id="dataImage.id"
              :src="dataImage.src"
              :alt="dataImage.alt"
              :height="h"
              :width="w"
              :class="`${rootClass}__img`"
            />
          </b-overlay>
          <label
            v-if="useLabel"
            :for="dataImage.id"
            :class="`${rootClass}__lbl bg-white w-full font-bold pl-1`"
            >{{ dataImage.alt }}</label
          >
        </div>
      </li>
    </ul>
  </div>
</template>
  
  <script>
import { BOverlay, BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";
export default {
  name: "SelectImage",
  components: { BOverlay, BListGroup, BListGroupItem, BBadge },
  props: {
    dataImages: {
      type: Array,
      default: () => [],
    },
    selectedImages: {
      type: Array,
      default: () => [],
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    useLabel: {
      type: Boolean,
      default: false,
    },
    rootClass: {
      type: String,
      default: "vue-select-image",
    },
    activeClass: {
      type: String,
      default: "--selected",
    },
    h: {
      type: String,
      default: "auto",
    },
    w: {
      type: String,
      default: "auto",
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      singleSelected: {
        id: "",
      },
      multipleSelected: [],
    };
  },
  computed: {
    dataImagesLocal: function () {
      return this.dataImages || [];
    },
  },
  mounted() {
    // set initial selectedImage
    this.setInitialSelection();
  },
  methods: {
    classThumbnail(selectedId, imageId, isDisabled) {
      const baseClass = `${this.rootClass}__thumbnail`;
      if (isDisabled) {
        return `${baseClass} ${baseClass}--disabled`;
      }
      if (selectedId === imageId) {
        return `${baseClass} ${baseClass}${this.activeClass}`;
      }
      return `${baseClass}`;
    },
    classThumbnailMultiple(id, isDisabled) {
      const baseClass = `${this.rootClass}__thumbnail`;
      const baseMultipleClass = `${baseClass} is--multiple`;
      if (isDisabled) {
        return `${baseMultipleClass} ${baseClass}--disabled`;
      }
      if (this.isExistInArray(id)) {
        return `${baseMultipleClass} ${baseClass}${this.activeClass}`;
      }
      return `${baseMultipleClass}`;
    },
    onSelectImage(objectImage) {
      if (!objectImage.disabled) {
        this.singleSelected = Object.assign(
          {},
          this.singleSelected,
          objectImage
        );
        this.$emit("onselectimage", objectImage);
      }
    },
    isExistInArray(id) {
      return this.multipleSelected.find((item) => {
        return id === item.id;
      });
    },
    removeFromSingleSelected() {
      this.singleSelected = {};
      this.$emit("onselectimage", {});
    },
    removeFromMultipleSelected(id, dontFireEmit) {
      this.multipleSelected = this.multipleSelected.filter((item) => {
        return id !== item.id;
      });
      if (!dontFireEmit) {
        this.$emit("onselectmultipleimage", this.multipleSelected);
      }
    },
    resetMultipleSelection() {
      this.multipleSelected = [];
    },
    onSelectMultipleImage(objectImage) {
      if (!objectImage.disabled) {
        if (!this.isExistInArray(objectImage.id)) {
          if (this.limit > 0) {
            if (this.multipleSelected.length < this.limit) {
              this.multipleSelected.push(objectImage);
              this.$emit("onselectmultipleimage", this.multipleSelected);
            } else {
              this.$emit("onreachlimit", this.limit);
            }
          } else {
            this.multipleSelected.push(objectImage);
            this.$emit("onselectmultipleimage", this.multipleSelected);
          }
        } else {
          this.removeFromMultipleSelected(objectImage.id, true);
          this.$emit("onselectmultipleimage", this.multipleSelected);
        }
      }
    },
    setInitialSelection() {
      if (this.selectedImages) {
        if (!this.isMultiple && this.selectedImages.length === 1) {
          this.singleSelected = Object.assign({}, this.selectedImages[0]);
        } else {
          this.multipleSelected = [].concat(this.selectedImages);
        }
      }
    },
  },
};
</script>
  
  <style src="./vue-select-image.css"></style>