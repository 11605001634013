<template>
  <b-card
    :flat="true"
    no-body
    :class="`${true ? 'shadow-none' : ''}`"
  >
    <b-row class="p-1">
      <div class="ml-1 mt-1 mb-1 text-lg font-bold">
        User Base Templates <span class="text-sm italic"> (admin only)</span>
      </div></b-row
    >
    <b-row class="p-1">
      <b-col>
        <div >
          <div :class="`${selectedUserError ? 'text-red-400' : ''}`">user <span v-if="selectedUserError">(no user chosen)</span></div>
          
          <b-form-select
            v-model="selectedUser"
            :options="userOptions"
            size="sm"
            class="border-red"
            @change="getTemplates()"
          >
          </b-form-select>
        
        </div>
      </b-col>
      <b-col>
        <div>
         <div :class="`${selectedTypeError ? 'text-red-400' : ''}`">type <span v-if="selectedTypeError">(no template type chosen)</span></div>
          <b-form-select
            v-model="selectedType"
            :options="typeOptions"
            size="sm"
          ></b-form-select>
        </div>
      </b-col>
      <b-col>
        <div>
         <div :class="`${selectedUserError ? 'text-red-400' : ''}`">id <span v-if="selectedUserError">(no template id)</span></div>
          <b-input-group size="sm">
            <b-form-input
              v-model="adminTemplateId"
              placeholder="input template id"
            ></b-form-input>
          </b-input-group></div
      ></b-col>
      <b-col>
        <div>
          <div class="invisible">button</div>
          <b-button size="sm" @click="setBaseTemplate()"> Add / Update </b-button>
        </div></b-col
      >
    </b-row>
    <b-row class="p-1 m-3">
      <b-list-group v-if="adminTemplates.length > 0">
        <b-list-group-item v-for="(item, index) in adminTemplates" :key="index"
          ><span class="font-bold">{{ item['type'] }}:</span> <span class="text-sm">{{item['id']}}</span> </b-list-group-item
        >
      </b-list-group>
      <b-list-group v-else>
        <b-list-group-item v-for="(item, index) in adminTemplates" :key="index"
          ><span class="font-bold">no templates assigned</span>  </b-list-group-item
        >
      </b-list-group>
    </b-row>
  </b-card>
</template>


<script>
import {
  BCol,
  BRow,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCol,
    BRow,

    BCard,
    //BCardBody,
    BButton,

    BInputGroup,
    BFormInput,

    BFormSelect,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      selectedUser: "",
      userOptions: [],
      selectedUserError: false,
      selectedType: "",
      typeOptions: ["filed", "served", "hearing", "disposed", "garnish"],
      selectedTypeError: false,
      adminTemplateId: "",
      adminTemplates: [],
      adminTemplateIdError: false,
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      var l1 = this;

      l1.$AuthAxios.instance
        .get("/api-access/exports/all-users/", {})
        .then(function (res) {
          l1.userOptions = res.data;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTemplates(){
  
      var l1 = this;

      l1.$emit('userChanged', {user: l1.selectedUser})
          l1.$AuthAxios.instance
        .get("/api-access/exports/base-templates/", {
          params: {
            user: l1.selectedUser,
            type: l1.selectedType,
            id: l1.adminTemplateId
          }
        })
        .then(function (res) {
         
          l1.adminTemplates = res.data
      
        })
        .catch((error) => {
          console.log(error)
        });

    },
    setBaseTemplate() {
      var l1 = this;

      if (!l1.selectedUser) {
        l1.selectedUserError = true;
      } else {
        l1.selectedUserError = false
      }

      if (!l1.selectedType){
        l1.selectedTypeError = true
      } else {
        l1.selectedTypeError = false
      }

      if (l1.adminTemplateId) {
        l1.adminTemplateIdError = true
      } else {
        l1.adminTemplateIdError = false
      }


      if (l1.selectedUser && l1.selectedType && l1.adminTemplateId) {

      l1.$AuthAxios.instance
        .post("/api-access/exports/base-templates/", {
          params: {
            user: l1.selectedUser,
            type: l1.selectedType,
            id: l1.adminTemplateId
          }
        })
        .then(function (res) {
          l1.adminTemplates = res.data
      
        })
        .catch((error) => {
               if (error.response.status === 406) {
    
              const toaster = "b-toaster-bottom-full";
              l1.$toast({
              component: ToastificationContent,
              props: {
                title: "That Template does not exist!",
                toaster,
                solid: true,
                icon: "EditIcon",
                variant: "warning",
                //"Your export name has already been used. Choose a new name."
              },
            });
          }
        });

      }
    },
  },
};
</script>
