<template>
  <b-col cols="12">
    <b-col v-if="popUp" cols="12">
      <main-template-base-select
        v-if="$can('post', 'siteOwner')"
        @userChanged="userChanged"
      />
      <b-card
        :key="increment"
        :flat="mailExport"
        no-body
        :class="`${mailExport ? 'shadow-none' : ''}`"
      > 
        <b-col v-for="(item, key) in popUp" :key="key" cols="12" class="mb-2">
          <div class="font-bold w-full text-xl my-1">
            {{ item.name }} Templates
          </div>

          <b-overlay
            :ref="`select-${key}`"
            :show="popUp[key].show"
            opacity="0.99"
            variant="white"
            rounded="lg"
            :class="`border rounded-lg bg-gray-100 ${
              !mailExport ? 'mx-4 shadow-inner' : ''
            }`"
          >
            <template #overlay style="max-width: 400px">
              <div class="relative">
                <b-col
                  class="invisible"
                  cols="12"
                  style="min-width: 400px; max-width: 400px"
                >
                  .
                </b-col>
                <div v-if="popUp[key].loading" class="text-center">
                  <b-spinner class="mr-1" label="Small Spinner" />
                </div>
                <div v-else-if="popUp[key].sure" class="text-center">
                  <div class="my-2 text-danger font-extrabold">
                    ARE YOU SURE YOU WANT TO PERMANENTLY DELETE?
                  </div>
                  <b-button
                    ref="cancel"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    aria-describedby="cancel-label"
                    class="mr-5"
                    @click="deleteFile(selectedFile[key], key)"
                  >
                    <b-row>
                      <b-col cols="12">
                        <span class="align-middle"> YES </span>
                      </b-col>
                    </b-row>
                  </b-button>

                  <b-button
                    ref="cancel"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    aria-describedby="cancel-label"
                    class="mr-5 text-center"
                    @click="
                      popUp[key].show = false;
                      popUp[key].sure = false;
                      removeSelected(key);
                    "
                  >
                    <b-row>
                      <b-col cols="12">
                        <span class="align-middle"> No </span>
                      </b-col>
                    </b-row>
                  </b-button>
                </div>
                <div v-else-if="popUp[key].create">
                  <div>
                    <div class="my-2 text-xl font-bold">
                      This will create a starter template
                    </div>
                    <div class="text-sm font-bold">Minimum Required fields</div>
                    <div
                      class="border bg-gray-200 mb-1"
                      style="
                        min-height: 30px;
                        max-height: 30px;
                        overflow-x: scroll;
                      "
                    >
                      <span class="text-xs italic pl-1">
                        {{ popUp[key].required1 }}
                      </span>
                    </div>

                    <div class="text-sm font-bold">
                      Required for suitinfo.net links.
                    </div>
                    <div
                      class="border bg-gray-200 mb-2"
                      style="
                        min-height: 30px;
                        max-height: 30px;
                        overflow-x: scroll;
                      "
                    >
                      <span class="text-xs italic pl-1">
                        {{ popUp[key].required2 }}
                      </span>
                    </div>
                  </div>
                  <b-row>
                    <b-input-group>
                      <b-form-input
                        v-model="templateName"
                        placeholder="Name Template (optional)"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-secondary"
                          @click="createNewTemplate(key)"
                        >
                          Create
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-button
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      variant="flat-dark"
                      size="sm"
                      class="btn-icon rounded-circle fixed top-0 right-0"
                      @click="
                        $refs[key][0].removeFromSingleSelected();
                        popUp[key].create = false;
                        popUp[key].show = false;
                      "
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-row>
                </div>
                <div v-else-if="popUp[key].upload">
                  <div>
                    <div class="my-2 text-xl font-bold">
                      Please Choose a Document to Upload
                    </div>

                    <div class="text-sm font-bold">Minimum Required fields</div>
                    <div
                      class="border bg-gray-200 mb-1"
                      style="
                        min-height: 30px;
                        max-height: 30px;
                        overflow-x: scroll;
                      "
                    >
                      <span class="text-xs italic pl-1">
                        {{ popUp[key].required1 }}
                      </span>
                    </div>

                    <div class="text-sm font-bold">
                      Required for suitinfo.net links.
                    </div>
                    <div
                      class="border bg-gray-200 mb-2"
                      style="
                        min-height: 30px;
                        max-height: 30px;
                        overflow-x: scroll;
                      "
                    >
                      <span class="text-xs italic pl-1">
                        {{ popUp[key].required2 }}
                      </span>
                    </div>
                  </div>

                  <div>
                    <b-overlay :show="progressShow" rounded="sm" no-fade>
                      <template #overlay>
                        <div>
                          <div class="invisible">
                            ...........................................................................................
                          </div>
                          <b-progress
                            animated
                            :value="uploadPercentage"
                            variant="primary"
                            class="progress-bar-primary"
                          />
                        </div>
                      </template>
                      <b-form-file
                        id="wildcard"
                        v-model="file"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/plain"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @input="fileUpload(key)"
                      />
                      <b-button
                        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                        variant="flat-dark"
                        size="sm"
                        class="btn-icon rounded-circle fixed top-0 right-0"
                        @click="
                          $refs[key][0].removeFromSingleSelected();
                          popUp[key].upload = false;
                          popUp[key].show = false;
                        "
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-overlay>
                  </div>
                </div>
                <div v-else class="text-center">
                  <div
                    tabindex="-1"
                    role="dialog"
                    aria-modal="false"
                    aria-labelledby="form-confirm-label"
                    class="text-center p-3"
                  >
                    <div class="d-flex">
                      <b-button
                        v-if="mailExport"
                        ref="cancel"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="primary"
                        class="mr-2"
                        @click="
                          popUp[key].show = false;
                          emitSelectedFile();
                        "
                      >
                        <div class="flex flex-row items-center justify-center">
                          <feather-icon icon="CheckIcon" class="text-xs mr-1" />
                          <span class="align-middle text-xs"> Select </span>
                        </div>
                      </b-button>
                      <b-button
                        ref="cancel"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="outline-secondary"
                        class="mr-2"
                        @click="
                          commitAction('edit', key);
                          popUp[key].show = false;
                        "
                      >
                        <div class="flex flex-row items-center justify-center">
                          <span class="align-middle text-xs">
                            View / Edit
                          </span>
                        </div>
                      </b-button>

                      <b-button
                        ref="cancel"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-danger"
                        size="sm"
                        class="mr-2"
                        @click="commitAction('delete', key)"
                      >
                        <div class="flex flex-row items-center justify-center">
                          <feather-icon
                            icon="Trash2Icon"
                            class="text-xs mr-1"
                          />
                          <span class="text-xs"> Delete </span>
                        </div>
                      </b-button>

                      <b-button
                        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                        variant="flat-dark"
                        size="sm"
                        class="btn-icon rounded-circle fixed top-0 right-0"
                        @click="
                          $refs[key][0].removeFromSingleSelected(), selected;
                          (popUp[key].show = false), delete selectedFile[key];
                        "
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="pt-2 ml-2" style="height: 320px; overflow-x: scroll">
              <vue-select-image
                :ref="key"
                :use-label="true"
                :data-images="item.files"
                @onselectimage="onSelectTemplate"
              >
              </vue-select-image>
            </div>
          </b-overlay>
        </b-col>
      </b-card>
    </b-col>
    <b-overlay
      v-else
      show="true"
      rounded="sm"
      style="min-width: 400px; max-width: 400px, height: 320px;"
    >
      <b-card title="Card with overlay" :aria-hidden="show ? 'true' : null">
        <b-col style="min-width: 400px; max-width: 400px, height: 320px;">
          ...
        </b-col>
      </b-card>
    </b-overlay>
  </b-col>
</template>
  
  <script>
import {
  BCol,
  BRow,
  BCard,
  //BCardBody,
  BOverlay,
  BButton,
  BSpinner,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormFile,
  BProgress,
} from "bootstrap-vue";
import MainTemplateBaseSelect from "./MainTemplateBaseSelect.vue";
import VueSelectImage from "./selectImage.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCol,
    BRow,
    VueSelectImage,
    BCard,
    //BCardBody,
    BButton,
    BSpinner,
    BOverlay,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormFile,
    BProgress,
    MainTemplateBaseSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    mailExport: {
      type: Boolean,
      default: false,
    },
    mailTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      setBase: function () {},
      progressShow: false,
      file: [],
      uploadPercentage: 1,
      templateName: "",
      filedShow: false,
      selectedFile: {},
      popUpHold: {},
      popUp: {},
      basePopUpHold: {
        Filed: {
          key: "Filed",
          name: "Recently Filed",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Filed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Filed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Served: {
          key: "Served",
          name: "Recently Served",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Served",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },

            {
              id: 2,
              key: "Served",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Hearing: {
          key: "Hearing",
          name: "Upcoming Hearing",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Hearing",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Hearing",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Disposed: {
          key: "Disposed",
          name: "Disposed / Judgement",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Disposed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Disposed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Garnish: {
          key: "Garnish",
          name: "Pending Garnishment / Levy",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Garnish",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Garnish",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Dismissed: {
          key: "Dismissed",
          name: "Dismissed",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Dismissed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Dismissed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
      },
      increment: 0,
      basePopUp: {
        Filed: {
          key: "Filed",
          name: "Recently Filed",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Filed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Filed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Served: {
          key: "Served",
          name: "Recently Served",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Served",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Served",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Hearing: {
          key: "Hearing",
          name: "Upcoming Hearing",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Hearing",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Hearing",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Disposed: {
          key: "Disposed",
          name: "Disposed / Judgement",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            //{
            //  id: 1,
            //  key: "Disposed",
            //  src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
            //  file_link: "",
            //  file_name: "Upload",
            //  alt: "Upload",
            //},
            {
              id: 2,
              key: "Disposed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Garnish: {
          key: "Garnish",
          name: "Pending Garnishment / Levy",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Garnish",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Garnish",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
        Dismissed: {
          key: "Dismissed",
          name: "Dismissed",
          show: true,
          sure: false,
          loading: true,
          create: false,
          upload: false,
          required2: "{{qr_url}} {{full_id_url}} {{url_id}} {{secret_pin}}",
          required1:
            "{{today_date}} {{defendant}} {{street}} {{city}} {{state}} {{zip_code}} {{case_title}} {{case_number}} {{defendant}} {{plaintiff}} {{garnishment_date}} {{employer_info}} {{amount}} {{mailing_phone_number}}",

          files: [
            {
              id: 1,
              key: "Dismissed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Ffile_upload.png?alt=media&token=9c49a59c-bf2b-4c5b-a380-2b79ac3aeea6",
              file_link: "",
              file_name: "Upload",
              alt: "Upload",
            },
            {
              id: 2,
              key: "Dismissed",
              src: "https://firebasestorage.googleapis.com/v0/b/publicdigital.appspot.com/o/website%2Fcreate_new_template.png?alt=media&token=856f0df3-1743-4e43-9305-8b31ab6513c6",
              file_link: "",
              file_name: "create",
              alt: "Create",
            },
          ],
        },
      },
      dataImages: {},
      templates: {},
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    };
  },

  async created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");

    var l1 = this;

    const setBase = function () {
      if (l1.mailTypes.length > 0) {
        var newPop = {};
        var newPopHold = {};

        var addElement = function (ElementList, element, key) {
          ElementList[key] = element;
        };

        l1.mailTypes.forEach((element) => {
          const mType = element[0].toUpperCase() + element.slice(1);

          addElement(newPopHold, l1.basePopUpHold[mType], mType);

          addElement(newPop, l1.basePopUp[mType], mType);
        });

        l1.popUpHold = newPopHold;
        l1.popUp = newPop;

        return;
      } else {
        l1.popUpHold = l1.basePopUpHold;
        l1.popUp = l1.basePopUp;

        return;
      }
    };

    l1.setBase = setBase;

    await setBase();

    l1.getTemplates(null, null, null);
  },

  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth);
  },
  methods: {
    async userChanged(value) {
      this.setBase();

      this.refreshArray();

      this.getTemplates(null, value.user, true);
    },
    emitSelectedFile() {
      this.$emit("updateSelectedTemplate", this.selectedFile);
    },
    scrollIntoView(key) {
      this.$nextTick(function () {
        const el = this.$refs[`select-${key}`][0].$el;

        this.$refs[`select-${key}`][0].scrollTop = el.offsetTop;
      });
    },
    uploadPercent() {
      if (this.uploadPercentage >= 85) {
        return 85;
      }
      return this.uploadPercentage;
    },
    removeSelected(key) {
      this.$refs[key][0].removeFromSingleSelected();
    },
    commitAction(action, key = false) {
      if (action === "edit") {
        window.open(this.selectedFile[key].file_link);
      }
      if (action === "delete") {
        this.popUp[key].sure = true;
      }
      console.log(action);
    },
    onSelectTemplate(image) {
      if (image.key) {
        delete this.selectedFile[image.key];
        this.selectedFile[image.key] = image;
        this.popUp[image.key].show = true;

        if (image.file_name === "create") {
          this.popUp[image.key].create = true;
          delete this.selectedFile[image.key];
        }
        if (image.file_name === "Upload") {
          this.popUp[image.key].upload = true;
          delete this.selectedFile[image.key];
        }
      }
      this.scrollIntoView(image.key);

      //window.open(image.file_link);
      //console.log(image);
    },
    updateArray(data) {
      var l1 = this;
      Object.keys(l1.popUp).forEach(function (key) {
        if (data[key]) {
          data[key].forEach(function (file) {
            l1.popUp[key].files.push(file);
            l1.popUp[key].loading = false;
            l1.popUp[key].show = false;
          });
        }
        l1.popUp[key].loading = false;
        l1.popUp[key].show = false;
      });
    },
    refreshArray() {
      var l1 = this;
      Object.keys(l1.popUp).forEach(function (key) {
        const base = l1.popUp[key].files.slice(0, 2);

        l1.popUp[key].files = base;
        l1.popUp[key].loading = true;
        l1.popUp[key].show = true;
      });
    },
    deleteFile(file, key) {
      var l1 = this;

      l1.popUp[key].sure = false;
      l1.popUp[key].loading = true;

      l1.$AuthAxios.instance
        .delete("/api-access/exports/templates/", {
          params: { file: file.file_id },
        })
        .then(function () {
          l1.$emit("incrementEvent");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTemplates(key = null, user = null, reset = null) {
      var l1 = this;

      l1.$AuthAxios.instance
        .get("/api-access/exports/templates/", { params: { user: user } })
        .then(async function (res) {
          if (!reset) {
            var copiedObject = Object.assign({}, l1.popUpHold);
            var copiedObject2 = Object.assign({}, l1.popUpHold);
            l1.popUpHold = copiedObject2;
            l1.popUp = copiedObject;
          }

          l1.updateArray(res.data);

          if (key) {
            l1.popUp[key].loading = false;
            l1.popUp[key].show = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return null;
    },
    refreshComponent() {
      this.$emit("incrementEvent");
    },
    createNewTemplate(key) {
      var l1 = this;

      l1.popUp[key].create = false;
      l1.popUp[key].loading = true;

      l1.$AuthAxios.instance
        .post("/api-access/exports/templates/", {
          params: { file: l1.removeSpecialChars(l1.templateName), key: key },
        })
        .then(function (res) {
          window.open(res.data.created_file["webViewLink"]);

          l1.refreshComponent();
        })
        .catch((error) => {
          console.log(error);
        });
      return null;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    fileUpload(key) {
      var l1 = this;
      l1.progressShow = true;

      let formData = new FormData();
      formData.append("file", l1.file);
      formData.append("template_type", key);

      l1.$AuthAxios.instance
        .put("/api-access/exports/templates/", formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          },
          onUploadProgress: function (progressEvent) {
            var uploadPercent = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            if (uploadPercent >= 85) {
              l1.uploadPercentage = 85;
            } else {
              l1.uploadPercentage = uploadPercent;
            }
          }.bind(l1),
        })
        .then(function (res) {
          l1.uploadPercentage = 100;
          l1.sleep(1000).then(() => {
            window.open(res.data.created_file["webViewLink"]);
            l1.refreshComponent();
          });
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    removeSpecialChars(str) {
      return str
        .replace(/(?!\w|\s)./g, "")
        .replace(/\s+/g, " ")
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2");
    },
  },
};
</script>




    