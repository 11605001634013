<template>
  <templates
    :key="increment"
    :mail-export="mailExport"
    :mail-types="mailTypes"
    v-on="$listeners"
    @incrementEvent="incrementKey"
  />
</template>

<script>
import Templates from "./MainTemplate.vue";
export default {
  components: { Templates },
  props: {
    mailTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    mailExport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      increment: 0,
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    };
  },

  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },

  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth);
  },
  methods: {
    incrementKey() {
      this.increment += 1;
    },
  },
};
</script>
